<template>
  <v-app>
    <component :is="getThemeMode.layout" />
  </v-app>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data () {
      return {
        layoutName: '',
      }
    },

    computed: {
      ...mapGetters(['getThemeMode']),
    },
  }
</script>
